<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="summary">
					<div class="summary_title">
						<h3>产品概述</h3>
					</div>
					<div class="summary_content">
						<div class="content_left">
							<img src="../../assets/images/testing/2-1/00.png" alt="">
						</div>
						<div class="content_right">
							<p>高性价比的信息系统安全检查。基于国家网络信息安全标准由专业技术人员对Web应用或网站开展专业安全检查。将传统的信息安全测评服务分解细化，客户可根据自身情况，选择不同的检查项和检查套餐。</p>
						</div>
					</div>
				</div>

				<div class="scene">
					<div class="scene_title">
						<h3>应用场景</h3>
					</div>
					<div class="scene_content">
						<div class="scene_left">
							<p><span>1.防范于未然：</span>在获得客户授权的前提下,寻找并利用信息系统存在的安全漏洞,模拟恶意攻击者(俗称“黑客”)的攻击方法,以“非法“方式“入侵”信息系统,从而确认安全漏洞的存在及其安全等级,并进一步确认黑客可能的攻击方法和可能造成的危害,对信息系统的安全进行评估,并提出相应的安全对策，避免业务安全隐患所导致的严重损失。</p>
							<p><span>2.亡羊补牢：</span>在发生安全事件后进行渗透测试，对安全隐患进行查漏补缺，预防下次事件的发生。</p>
						</div>
						<div class="scene_right">
							<img src="../../assets/images/testing/2-1/04.jpg" alt="">
						</div>
					</div>
				</div>

				<div class="business">
					<div class="business_title">
						<h3>业务开展的必要性</h3>
					</div>
					<div class="business_content">
						<div class="content_one">
							<div class="left">
								<h4>1.网站被入侵、篡改、挂马、拖库、信息泄露，黑客、木马、病毒无孔不入网站安全状况堪忧而不自知。</h4>
								<ul>
									<li>a.在全球范围内，每天有30,000 个网站被黑客入侵</li>
									<li>b.1/3的网络攻击发生在中国</li>
									<li>c.中国企业每年因网络攻击损失百亿美元</li>
									<li>d.2017WannaCry勒索病毒导致全球损失550亿元</li>
									<li>e.企业从被入侵到发现入侵的平均时间为1.6年</li>
									<li>f.每39秒，Web上的某个地方就会发生一次新的攻击</li>
								</ul>
							</div>
							<div class="right">
								<img src="../../assets/images/testing/2-1/05.jpg" alt="">
							</div>
						</div>
						<div class="content_two">
							<div class="left">
								<img src="../../assets/images/testing/2-1/06.jpg" alt="">
							</div>
							<div class="right">
								<h4>2.为保障网站安全，网站需要进行一次健康检查，通过Web安全健康检查服务，可以安稳地掌握网站最新的安全状态。</h4>
								<ul>
									<li>a.一般网站：一个季度开展一次安全检查比较合适</li>
									<li>b.重要网站：每月进行一次安全检查是必要的</li>
									<li>c.更为重要的网站，还需进行代码审计</li>
									<li>d.曾遭受入侵的网站：建议建立定期安全检查的机制</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div class="profit">
					<div class="profit_title">
						<h3>客户收益</h3>
						<h6>给你一个五星级的安全网站</h6>
					</div>
					<div class="profit_content">
						<ul>
							<li>
								<img src="../../assets/images/testing/2-1/5.jpg" alt="">
								<p>基于国家信息安全标准规范——权威可信</p>
							</li>
							<li>
								<img src="../../assets/images/testing/2-1/2.jpg" alt="">
								<p>专业信息安全服务团队服务——专业可靠</p>
							</li>
							<li>
								<img src="../../assets/images/testing/2-1/1.jpg" alt="">
								<p>化解细化信息安全检查项目——清晰明了</p>
							</li>
							<li>
								<img src="../../assets/images/testing/2-1/4.jpg" alt="">
								<p  style="width:120px">信息检查项<br>目明码标价<br>——高性价比</p>
							</li>
							<li>
								<img src="../../assets/images/testing/2-1/3.jpg" alt="">
								<p>自由选择搭配安全检查套餐——随心所欲</p>
							</li>
						</ul>
					</div>
				</div>

				<div class="detailed">
					<div class="detailed_title">
						<h3>产品详细</h3>
					</div>
					<div class="detailed_content">
						<div class="content_one">
							<h5>1.服务依据：</h5>
							<ul>
								<li>《中华人民共和国网络安全法》</li>
								<li>《中华人民共和国数据安全法》</li>
								<li>《中华人民共和国个人信息保护法》</li>
								<li>《中华人民共和国计算机信息系统安全保护条例》</li>
								<li>《通信网络安全防护管理办法（工业和信息化部令第11号）》</li>
								<li>《工业和信息化部关于加强电信和互联网行业网络安全工作的指导意见（工信部保〔2014〕368号）》</li>
								<li>《关于做好2017年互联网新技术新业务安全评估重点工作的通知（工网安函〔2017〕168号）》</li>
							</ul>
						</div>
						<div class="content_two">
							<h5>2.服务内容：</h5>
							<img src="../../assets/images/testing/2-1/01.jpg" alt="">
						</div>
						<div class="content_two">
							<h5>3.安全检查项说明：</h5>
							<img src="../../assets/images/testing/2-1/02.jpg" alt="">
						</div>
						<div class="content_two">
							<h5>4.套餐说明：</h5>
							<img src="../../assets/images/testing/2-1/003.jpg" alt="">
							<p>说明：根据自身需求，选择不同的检查项和检查套餐。其中设备数不超过30台。Web渗透测试不超过10个功能点。代码审计代码不超过10万行。超过须另行报价。</p>
						</div>
						<div class="content_two">
							<h5>5.服务流程：</h5>
							<img src="../../assets/images/testing/2-1/03.jpg" alt="">
						</div>
					</div>
				</div>

				<div class="our">
					<div class="our_title">
						<h3>我们的优势</h3>
					</div>
					<div class="our_content">
						<p><span>1.优秀的安全技术团队:</span>安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</p>
						<p><span>2.公司资质&知识产权:</span>具备国家网络信息安全主管部门颁发的权威安全认证资质及国际权威认证资质。</p>
						<div class="our_bg">
							<div class="bg_left">
								<ul>
									<li>国家信息安全服务资质(安全工程类)</li>
									<li>国家信息安全风险评估资质</li>
									<li>国家通信网络安全服务风险评估资质</li>
									<li>国家信息安全应急处理服务资质</li>
									<li>国家信息系统安全集成服务资质</li>
									<li>国家信息安全漏洞库支持单位</li>
									<li>国际信息安全管理体系ISO 27001认证</li>
									<li>国际质量管理体系ISO9001认证</li>
									<li>国际环境管理体系ISO 14001认证</li>
									<li>高新技术企业</li>
									<li>软件企业</li>
									<li>企业研究开发机构证书</li>
								</ul>
							</div>
							<div class="bg_right">
								<img src="../../assets/images/app/qualifications.jpg" alt="">
							</div>
						</div>
					</div>
				</div>

				<div class="case">
					<div class="case_title">
						<h3>相关案例</h3>
					</div>
					<div class="case_content">
						<ul>
							<li>1.深圳市公园管理中心多套业务系统的Web及系统安全检测服务项目</li>
							<li>2.延安市统计局——Web安全检测服务项目</li>
							<li>3.广州某学堂Web应用系统安全检测服务项目</li>
						</ul>
					</div>
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"WEB安全健康检查",
			content:"将传统的信息安全测评服务分解细化，客户可根据自身情况，选择不同的检查项和检查套餐。",
            cc:"基于国家网络信息安全标准由专业技术人员对Web应用或网站开展专业安全检查",
			price:'在线咨询',
			url:require('../../assets/images/testing/2-1/800.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}

.summary{
	width: 880px;
	height: 393px;
	background-color: #143AA9;
	.summary_title{
		h3{
			font-size: 29px;
			padding-top: 21px;
			text-align: center;
			color: white;
			font-weight: bold;
		}
	}
	.summary_content{
		display: flex;
		justify-content: space-around;
		align-items: center;
		.content_left{
			width: 392px;
			height: 337px;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.content_right{
			width: 428px;
			p{
				font-size: 20px;
				line-height: 30px;
				color: white;
				text-indent: 2em;
			}
		}
	}
}
.scene{
	margin-top: 40px;
	.scene_title{
		h3{
			font-size: 29px;
			text-align: center;
			color: #143AA9;
			font-weight: bold;
		}
	}
	.scene_content{
		display: flex;
		justify-content: space-around;
		align-items: flex-start;
		margin-top:53px;
		.scene_left{
			width: 572px;
			p{
				font-size: 20px;
				line-height: 30px;
				margin-bottom: 30px;
				span{
					font-weight: bold;
					color: #143AA9;
				}
			}
		}
		.scene_right{
			width: 266px;
			height: 307px;
			img{
				width: 100%;
				height: 100%;
			}
		}
	}
}
.business{
	margin-top: 58px;
	.business_title{
		h3{
			font-size: 29px;
			text-align: center;
			color: #143AA9;
			font-weight: bold;
		}
	}
	.business_content{
		.content_one{
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			margin-top: 43px;
			.left{
				width: 576px;
				h4{
					font-size: 20px;
					line-height: 28px;
					color: #143AA9;
					font-weight: bold;
				}
				ul{
					li{
						font-size: 20px;
						line-height: 30px;
					}
				}
			}
			.right{
				width: 260px;
				height: 282px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
		.content_two{
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			margin-top: 41px;
			.left{
					width: 318px;
					height: 282px;
					img{
						width: 100%;
						height: 100%;
					}
				}
			.right{
				width: 487px;
				h4{
					font-size: 20px;
					line-height: 35px;
					color: #143AA9;
					font-weight: bold;
				}
				ul{
					li{
						font-size: 20px;
						line-height: 35px;
					}
				}
			}
		}

	}
}
.profit{
	margin-top: 76px;
	.profit_title{
		h3{
			font-size: 29px;
			text-align: center;
			color: #143AA9;
			font-weight: bold;
		}
		h6{
			margin-top: 15px;
			font-size: 16px;
			text-align: center;
		}
	}
	.profit_content{
		margin-top: 37px;
		ul{
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-wrap: wrap;
			li{
				width: 290px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 66px;
				img{
					width: 91px;
					height: 93px;
					margin-bottom: 32px;
				}
				p{
					display: block;
					width: 132px;
					height: 90px;
					font-size: 20px;
					line-height: 30px;
					color: #143AA9;
					font-weight: bold;
				}
			}
		}
	}
}
.detailed{
	margin-top: 72px;
	.detailed_title{
		h3{
			font-size: 29px;
			text-align: center;
			color: #143AA9;
			font-weight: bold;
		}
	}
	.detailed_content{
		margin-top: 57px;
		padding: 0px 20px;
		.content_one{
			margin-bottom: 23px;
			h5{
				font-size: 18px;
				line-height: 30px;
				font-weight: bold;
				color: #143AA9;
			}
			ul{
				li{
					font-size: 18px;
					line-height: 30px;
				}
			}
		}
		.content_two{
			margin-bottom: 23px;
			h5{
				font-size: 18px;
				line-height: 30px;
				font-weight: bold;
				color: #143AA9;
			}
			img{
				margin-top: 30px;
				width: 849px
			}
			p{
				font-size: 16px;
				line-height: 30px;
			}
		}
	}
}
.our{
	margin-top: 79px;
	.our_title{
		h3{
			font-size: 29px;
			text-align: center;
			color: #143AA9;
			font-weight: bold;
		}
	}
	.our_content{
	
		margin-top: 56px;
		p{
				padding: 0 20px;
			font-size: 16px;
			line-height: 30px;
			span{
				font-size: 18px;
				line-height: 30px;
				color: #143AA9;
				font-weight: bold;
			}
		}
		.our_bg{
			background-color: #6C66E0;
			width: 873px;
			height: 422px;
			display: flex;
			justify-content: space-around;
			align-items: center;
			margin-top:10px;
			.bg_left{
				ul{
					li{
						font-size: 16px;
						line-height: 30px;
						color: white;
					}
				}
			}
			.bg_right{
				width: 367px;
				height: 367px;
				img{
					width: 100%;
					height: 100%;
				}
			}
		}
	}
}
.case{
	margin-top:96px;
	.case_title{
		h3{
			font-size: 29px;
			text-align: center;
			color: #143AA9;
			font-weight: bold;
		}
	}
	.case_content{
		padding: 40px 20px;
		ul{
			li{
				font-size: 16px;
				line-height: 30px;
			}
		}
	}
}



.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}






}


</style>







